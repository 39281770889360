import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function dropdownOption(theme: Theme): ComponentStyle {
  return {
    componentName: 'dropdownOption',
    baseStyle: {
      gap: theme.space.xs,
      backgroundColor: theme.colors.foreground,
      border: 'none',

      ['&:hover']: {
        backgroundColor: '#4b4e68'
      }
    },
    variants: {
      secondary: {}
    }
  };
}
