import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function displaySwitch(theme: Theme): ComponentStyle {
  return {
    componentName: 'displaySwitch',
    baseStyle: {
      flex: 1,
      backgroundColor: theme.colors.onSurface,
      borderRadius: theme.sizes['-3']
    },
    variants: {
      bubble: {
        background: 'none'
      }
    }
  };
}
