import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function dropdownList(theme: Theme): ComponentStyle {
  return {
    componentName: 'dropdownList',
    baseStyle: {
      border: 'none',
      borderRadius: theme.sizes['-4'],
      marginTop: theme.space['3xs']
    }
  };
}
