import {
  ArrowRightIcon,
  Box,
  Card,
  Flex,
  KpiBody,
  KpiCardBodyNumberTrend,
  KpiCardBodyPercentageTrend,
  KpiCardFooter,
  KpiCardValue,
  KpiFooter,
  KpiHeader,
  ThemedComponentWrapperProps
} from '@blockanalitica/ui';
import {
  CurrencyValueCurrency,
  CurrencyValueOptions,
  Notation
} from '@blockanalitica/ui/package/types/src/components/molecules/CurrencyValue/CurrencyValue';
import { PercentageValueOptions } from '@blockanalitica/ui/package/types/src/components/molecules/PercentageValue';
import { Size } from '@blockanalitica/ui/package/types/src/theme/types';
import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';

const Arrow = styled(ArrowRightIcon)`
  align-self: flex-end;
  color: ${(props) => props.theme.colors.muted};
`;

export type KpiCardData = Record<string, string | number | null>;

type KpiCardComponentsVariants = {
  kpiHeader?: string;
  kpiBody?: string;
  kpiFooter?: string;
  kpiCardFooter?: string;
  kpiCardValue?: string;
  kpiCardBodyPercentageTrend?: string;
  kpiCardBodyNumberTrend?: string;
  valueCurrencyValue?: string;
  valuePercentageValue?: string;
  trendCurrencyValue?: string;
  trendPercentageValue?: string;
};

export interface Options {
  trendIcon?: boolean | ReactNode;
  linkArrow?: boolean;
  linkArrowSize?: Size | number | string;
  notation?: Notation;
  currency?: CurrencyValueCurrency;
  trend?: boolean;
  usdTrend?: boolean;
  animated?: boolean;
  endlessPerSecond?: number;
  prefixIcon?: ReactNode;
  percentage?: boolean;
  variants?: KpiCardComponentsVariants;
  trendType?: string;
  valueOptions?: {
    currencyValueOptions?: CurrencyValueOptions;
    percentageValueOptions?: PercentageValueOptions;
  };
  valueTrendOptions?: {
    currencyValueOptions?: CurrencyValueOptions;
    percentageValueOptions?: PercentageValueOptions;
  };
  footerOptions?: {
    currencyValueOptions?: CurrencyValueOptions;
    percentageValueOptions?: PercentageValueOptions;
  };
}

export interface KpiCardProps
  extends Omit<ThemedComponentWrapperProps, 'title' | 'name'> {
  field: string;
  title: ReactNode;
  data: KpiCardData;
  footer?: ReactNode;
  extra?: ReactNode;
  options?: Options;
}

export default function KpiCard({
  field,
  title,
  data,
  footer,
  extra,
  options,
  ...rest
}: KpiCardProps) {
  const fields = useMemo(
    () => ({
      fieldBase: field,
      trendBase: `${field}_change`,
      trendPercBase: `${field}_change_percentage`,
      fieldBaseUsd: `${field}_usd`,
      trendBaseUsd: `${field}_usd_change`,
      trendPercBaseUsd: `${field}_usd_change_percentage`
    }),
    [field]
  );

  const mergedOptions = useMemo(
    () => ({
      trend: true,
      usdTrend: true,
      animated: true,
      prefixIcon: null,
      percentage: false,
      trendType: 'number',
      ...options
    }),
    [options]
  );

  const bodyTrendValue = useMemo<number | null>(() => {
    if (Math.abs(Number(data[fields.trendPercBase])) > 0) {
      return Number(data[fields.trendPercBase]);
    }

    return null;
  }, [data, fields.trendPercBase]);

  const bodyTrendValueChange = useMemo<number | null>(() => {
    if (Math.abs(Number(data[fields.trendBase])) > 0) {
      return Number(data[fields.trendBase]);
    }

    return null;
  }, [data, fields.trendBase]);

  const usdTrendValues = useMemo(() => {
    const trendBaseUsd = Number(data[fields.trendBaseUsd]);
    const trendPercBaseUsd = Number(data[fields.trendPercBaseUsd]);

    if (
      mergedOptions.usdTrend &&
      Math.abs(trendPercBaseUsd) > 0 &&
      !mergedOptions.percentage
    ) {
      return {
        trendBaseUsd,
        trendPercBaseUsd
      };
    }

    return null;
  }, [
    data,
    fields.trendBaseUsd,
    fields.trendPercBaseUsd,
    mergedOptions.percentage,
    mergedOptions.usdTrend
  ]);

  const usdValue = useMemo(() => {
    const value = Number(data[fields.fieldBaseUsd]);

    return Math.abs(value) > 0 ? value : null;
  }, [data, fields.fieldBaseUsd]);

  return (
    <Card {...rest}>
      {/* Necessesary extra wrapper when consumer uses mix-blend-mode and scale css props together */}
      <Box
        className="cardContentWrapper"
        display="flex"
        flex="1"
        gap="xs"
        flexDirection={['column', 'row']}>
        <Flex flexDirection="column" gap="xs" flex="1">
          <KpiHeader variant={mergedOptions.variants?.kpiHeader}>
            {title}
          </KpiHeader>

          <Flex flexDirection="row" flex="1">
            <Flex flexDirection="column" gap="xs" flex="1">
              <KpiBody
                gap="s"
                alignItems="center"
                justifyContent="space-between"
                variant={mergedOptions.variants?.kpiBody}>
                <Flex alignItems="baseline" gap="xs" width="95%" fontSize="4">
                  {mergedOptions.prefixIcon}

                  <KpiCardValue
                    percentage={mergedOptions.percentage}
                    animated={mergedOptions.animated}
                    value={Number(data[fields.fieldBase])}
                    endlessPerSecond={mergedOptions.endlessPerSecond}
                    options={mergedOptions}
                    variant={mergedOptions.variants?.kpiCardValue}
                  />

                  {mergedOptions.trend &&
                  bodyTrendValue !== null &&
                  bodyTrendValueChange !== null ? (
                    mergedOptions.trendType === 'number' ? (
                      <KpiCardBodyNumberTrend
                        value={bodyTrendValue}
                        valueChange={bodyTrendValueChange}
                        options={mergedOptions}
                        variant={mergedOptions.variants?.kpiCardBodyNumberTrend}
                      />
                    ) : (
                      <KpiCardBodyPercentageTrend
                        value={bodyTrendValue}
                        valueChange={bodyTrendValueChange}
                        notation={mergedOptions.notation}
                        options={mergedOptions}
                        variant={
                          mergedOptions.variants?.kpiCardBodyPercentageTrend
                        }
                      />
                    )
                  ) : null}
                </Flex>
              </KpiBody>

              {footer || usdValue || usdTrendValues ? (
                <KpiFooter variant={mergedOptions.variants?.kpiFooter}>
                  {footer ? (
                    footer
                  ) : (
                    <KpiCardFooter
                      value={usdValue}
                      trendValues={usdTrendValues}
                      variant={mergedOptions.variants?.kpiCardFooter}
                      options={mergedOptions}
                    />
                  )}
                </KpiFooter>
              ) : null}
            </Flex>

            {mergedOptions?.linkArrow ? (
              <Arrow
                className="kpiCardArrowLink"
                size={mergedOptions?.linkArrowSize ?? '2'}
              />
            ) : null}
          </Flex>
        </Flex>
        {extra ? <Flex justifyContent="flex-end">{extra}</Flex> : null}
      </Box>
    </Card>
  );
}
