import { Page, Tabs } from '@/components';
import { useSearchParams } from '@/hooks';
import KpisAndChart from './KpisAndChart';
import StablecoinRatesTable from './StablecoinRatesTable';
import StablecoinRatesEstimator from './StablecoinRatesEstimator';

export default function HomePage() {
  const [activeTab, setActiveTab] = useSearchParams(
    'tab',
    'stablecoin_pools'
  ) as [
    string,
    (value: string) => void,
    (name: string, value: string) => string
  ];

  const tabs = [
    {
      key: 'stablecoin_pools',
      title: 'Stablecoin Pools',
      content: <StablecoinRatesTable />
    },
    {
      key: 'stablecoin_rate_estimator',
      title: 'Stablecoin Rate Estimator',
      content: <StablecoinRatesEstimator />
    }
  ];
  return (
    <Page>
      <KpisAndChart />
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
    </Page>
  );
}
