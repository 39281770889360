import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';

export function dropdownHeader(theme: Theme): ComponentStyle {
  return {
    componentName: 'dropdownHeader',
    baseStyle: {
      gap: theme.space.xs,
      backgroundColor: theme.colors.onSurface,
      border: 'none',
      borderRadius: theme.sizes['-4'],
      padding: theme.space['xs']
    },
    variants: {
      secondary: {
        border: `solid 1px ${theme.colors.border}`,
        fontSize: theme.sizes['0'],
        color: theme.colors.muted,
        padding: theme.space['2xs'],

        ['&:hover']: {
          borderColor: theme.colors.primary
        }
      },
      ratesSection: {
        border: `solid 1px ${theme.colors.border}`,
        fontSize: theme.sizes['0'],
        color: theme.colors.muted,
        paddingTop: `${theme.sizes['-5']}`,
        paddingBottom: `${theme.sizes['-5']}`,
        ['&:hover']: {
          borderColor: theme.colors.primary
        }
      }
    }
  };
}
