import { Flex, Box } from '@blockanalitica/ui';
import { Outlet, Link } from 'react-router';
import { ReactNode } from 'react';
import { styled } from 'styled-components';
import {
  default as PoweredByBA
  // @ts-ignore
} from '@/assets/logos/powered_by_ba.svg?react';
import {
  default as SphereLogo
  // @ts-ignore
} from '@/assets/logos/sphere_with_name.svg?react';
import { ExternalLink } from '@/components';
import Footer from './Footer';

const ContentWrapper = styled(Flex)`
  box-sizing: border-box;
  padding: ${(props) =>
    `${props.theme.space['xs']} ${props.theme.space['s-m']} ${props.theme.space['s-m']}`};
`;

type LayoutProps = {
  children?: ReactNode;
};

export default function Layout({ children }: LayoutProps) {
  return (
    <Flex height="100%" flexWrap="nowrap" width="100%" gap="0">
      <ContentWrapper
        flexGrow={1}
        flexShrink={1}
        flexBasis={['100%', 'auto']}
        margin={'0 auto'}
        width="100%"
        maxWidth={['100vw', '80rem']}
        flexDirection="column"
        gap="s">
        <Flex justifyContent="space-between" alignItems="center">
          <Box
            // @ts-ignore
            as={Link}
            to="/">
            <SphereLogo height={50} />
          </Box>
          <ExternalLink color="white" href="https://blockanalitica.com">
            <PoweredByBA height={45} />
          </ExternalLink>
        </Flex>

        <main>{children ? children : <Outlet />}</main>

        <Footer />
      </ContentWrapper>
    </Flex>
  );
}
