import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function table(): ComponentStyle {
  return {
    componentName: 'table',
    baseStyle: {
      border: 'unset',
      borderRadius: 'unset'
    }
  };
}
