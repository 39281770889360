import { Page, KpiCard } from '@/components';
import { useApi } from '@/api';
import { useParams } from 'react-router';
import { PageSkeleton, EntityName } from '@/components';
import { CryptoIcon, Flex, Box } from '@blockanalitica/ui';
import ProtocolStablecoinsPoolsTable from './components/ProtocolStablecoinsPoolsTable';
import { protocolToTitleCase } from '@/utils/utils';

type Protocol = {
  protocol: string;
  protocol_symbol: string;
  total_debt: string;
  total_debt_change: string;
  total_debt_change_percentage: string;
};

export default function ProtocolPage() {
  const { protocol } = useParams() as {
    protocol: string;
  };

  const [data, error] = useApi<Protocol>(
    {
      url: `/protocol/${protocol}/`
    },
    {
      keepPreviousData: true
    }
  );

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <EntityName
            symbol1={<CryptoIcon name={data.protocol_symbol} size="6" />}>
            <Flex gap="3xs" flexDirection="column">
              <Box fontSize="4">{protocolToTitleCase(data.protocol)}</Box>
            </Flex>
          </EntityName>
        }>
        <KpiCard
          title="Total Stablecoin Borrow"
          field="total_debt"
          data={data}
          options={{
            currency: 'USD'
          }}
        />
        <ProtocolStablecoinsPoolsTable />
      </Page>
    );
  }
  return <PageSkeleton />;
}
