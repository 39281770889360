import { useSearchParams as useRouterSearchParams } from 'react-router';
import { useCallback } from 'react';

export default function useSearchParams(
  name: string,
  defaultValue: string | null = null
): [
  string | null,
  (value: string) => void,
  (name: string, value: string) => string
] {
  const [searchParams, setRouterSearchParams] = useRouterSearchParams();

  const paramValue = searchParams.get(name) ?? defaultValue;

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams);
      params.set(name, value ?? '');

      return params.toString();
    },
    [searchParams]
  );

  const setSearchParams = useCallback(
    (value: string) => {
      setRouterSearchParams((prevSearchParams) => {
        const updatedSearchParams = new URLSearchParams(prevSearchParams);
        updatedSearchParams.set(name, value ?? '');

        return updatedSearchParams;
      });
    },
    [name, setRouterSearchParams]
  );

  return [paramValue, setSearchParams, createQueryString];
}
