import { useTableProps } from '@blockanalitica/ui';
import { useState, useCallback } from 'react';

export default function RateTableFilters(sortingField: string) {
  const { pagination, sorting } = useTableProps({
    pageSize: 15,
    initialSorting: sortingField,
    filtersDebounceTime: 50
  });
  const [tokensFiltered, setTokensFiltered] = useState<string[]>([
    'DAI',
    'USDC',
    'USDS'
  ]);
  const [protocolsFiltered, setProtocolsFiltered] = useState<string[]>([
    'sparkprotocol',
    'aaveprotocol',
    'aaveprotocol_lido',
    'COMP',
    'Morpho'
  ]);
  const [collateralTokensFiltered, setCollateralTokensFiltered] = useState<
    string[]
  >(['WETH', 'wstETH']);

  const filterByToken = useCallback(
    (tokens: string[]) => setTokensFiltered(tokens),
    []
  );

  const filterByProtocol = useCallback(
    (protocols: string[]) => setProtocolsFiltered(protocols),
    []
  );

  const filterByCollateralToken = useCallback(
    (collateralTokens: string[]) =>
      setCollateralTokensFiltered(collateralTokens),
    []
  );

  return {
    pagination,
    sorting,
    tokensFiltered,
    protocolsFiltered,
    collateralTokensFiltered,
    filterByToken,
    filterByProtocol,
    filterByCollateralToken
  };
}
