import { useCallback, useMemo } from 'react';
import type { TimeUnit } from 'chart.js';
import _ from 'lodash';
import { ChartConfig } from '@blockanalitica/ui/package/types/src/components/charts/Chart';

export default function useChartPeriod(
  daysAgo: number | string
): [
  { labelPrefix: string; dateFormat: string; chartUnit: TimeUnit },
  (config?: ChartConfig) => ChartConfig
] {
  const period = useMemo(() => {
    let value: number;
    if (typeof daysAgo !== 'number') {
      value = Number(daysAgo);
    } else {
      value = daysAgo;
    }

    if (value <= 90) {
      return {
        labelPrefix: '',
        dateFormat: 'MMM dd, yyyy',
        chartUnit: 'week' as TimeUnit
      };
    }
    return {
      labelPrefix: '',
      dateFormat: 'MMM yyyy',
      chartUnit: 'month' as TimeUnit
    };
  }, [daysAgo]);

  const updateChartConfigWithPeriod = useCallback(
    (config?: ChartConfig) => {
      return _.merge(
        {
          options: {
            scales: {
              x: {
                time: {
                  unit: period.chartUnit
                }
              }
            }
          }
        },
        config || {}
      );
    },
    [period]
  );

  return [period, updateChartConfigWithPeriod];
}
