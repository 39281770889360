import { Box, Flex, XIcon, SubstackIcon } from '@blockanalitica/ui';
import { ExternalLink } from '@/components';
import {
  default as BALogo
  // @ts-ignore
} from '@/assets/logos/ba_logo_full.svg?react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Flex
      flexDirection="row"
      gap="xs"
      alignItems="center"
      justifyContent="center"
      paddingBottom="m">
      <Flex alignItems="center" gap="xs">
        <ExternalLink href="https://twitter.com/BlockAnalitica" color="muted">
          <XIcon size="0" />
        </ExternalLink>

        <ExternalLink href="https://blockanalitica.substack.com/" color="muted">
          <SubstackIcon size="0" />
        </ExternalLink>
      </Flex>
      <Box color="muted">|</Box>

      <ExternalLink color="white" href="https://blockanalitica.com">
        <BALogo height={30} />
      </ExternalLink>

      <Box color="muted" fontSize="-2">
        © {currentYear}. All rights reserved.
      </Box>
      <Box color="muted">|</Box>
      <ExternalLink
        href="https://blockanalitica.com/terms-of-service/"
        color="muted"
        fontSize="-2">
        Terms of Service
      </ExternalLink>
    </Flex>
  );
}
