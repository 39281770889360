import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function dropdown(): ComponentStyle {
  return {
    componentName: 'dropdown',
    baseStyle: {
      width: 'fit-content'
    }
  };
}
