import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function kpiHeader(theme: Theme): ComponentStyle {
  return {
    componentName: 'kpiHeader',
    baseStyle: {
      color: theme.colors.muted,
      fontWeight: 500,
      fontSize: theme.sizes['0']
    }
  };
}
