import { Sizes } from '@blockanalitica/ui/package/types/src/theme/sizes';

const sizes: Sizes = {
  '-5': '0.3516rem',
  '-4': '0.422rem',
  '-3': '0.5064rem',
  '-2': '0.6076rem',
  '-1': '0.7292rem',
  '0': '0.875rem',
  '1': '1.05rem',
  '2': '1.26rem',
  '3': '1.512rem',
  '4': '1.8144rem',
  '5': '2.1773rem',
  '6': '2.6127rem',
  '7': '3.1353rem',
  '8': '3.7623rem',
  '9': '4.5148rem',
  '10': '5.4178rem'
};

export default sizes;
