import { useMemo, ReactNode } from 'react';
import { Flex, Card, FlexProps } from '@blockanalitica/ui';
import styled, { css } from 'styled-components';

export interface TabFlexProps extends FlexProps {
  $active: boolean;
}

const TabFlex = styled(Flex)<TabFlexProps>`
  flex-direction: row;
  font-weight: 500;

  ${(props) => css`
    background-color: ${props.theme.colors.tabInactive};
    padding: ${props.theme.space['xs']} ${props.theme.space['s']};
    border-top-left-radius: ${props.theme.sizes['-3']};
    border-top-right-radius: ${props.theme.sizes['-3']};
    color: ${props.theme.colors.muted};

    &:hover {
      cursor: pointer;
      background-color: ${props.theme.colors.foreground};
      color: ${props.theme.colors.text};
    }
  `}

  ${(props) =>
    props.$active &&
    css`
      background-color: ${props.theme.colors.onSurface};
      color: ${props.theme.colors.text};

      // Disable hover for active tab
      &:hover {
        background-color: ${props.theme.colors.onSurface};
        color: ${props.theme.colors.text};
        cursor: default;
      }
    `}
`;

type Tab = {
  key: string;
  title: string;
  content: ReactNode;
};

interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  setActiveTab: (value: string) => void;
}

export default function Tabs({ tabs, activeTab, setActiveTab }: TabsProps) {
  const tabTitles = useMemo(
    () =>
      tabs.map(({ key, title }) => (
        <TabFlex
          key={key}
          $active={key === activeTab}
          onClick={() => setActiveTab(key)}>
          {title}
        </TabFlex>
      )),
    [activeTab, setActiveTab, tabs]
  );

  const activeContent = useMemo(
    () => tabs.find(({ key }) => key === activeTab)?.content,
    [activeTab, tabs]
  );

  return (
    <Flex flexDirection="column" flex="1">
      <Flex gap="2xs">{tabTitles}</Flex>
      <Card variant="tabs">{activeContent}</Card>
    </Flex>
  );
}
