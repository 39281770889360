import { ChartTimeFilter } from '@/components';
import {
  LineChart,
  Flex,
  Box,
  formatPercentage,
  Dropdown,
  DropdownOption,
  formatDatetime
} from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import DropdownAssetsFilter from '@/components/DropdownAssetsFilter';
import { useTheme } from 'styled-components';
import { protocolToTitleCase, generateColorFromPool } from '@/utils/utils';
import type { TooltipItem } from 'chart.js';
import { useChartPeriod } from '@/hooks';

export type RateHistory = {
  date: string;
  pool: string;
  rate: string;
  pool_id: string;
};

interface RatesChartProps {
  data: RateHistory[];
  callbacks: {
    filterByTime: (args: string) => void;
    filterByToken: (args: string[]) => void;
    filterByProtocol: (args: string[]) => void;
    filterByCollateralToken: (args: string[]) => void;
    changeApyTimeframe: (args: string | string[]) => void;
  };
  filters: {
    timeFilter: string;
    tokensFiltered: string[];
    protocolsFiltered: string[];
    tokensOptions: { key: string; value: string }[];
    protocolsOptions: { key: string; value: string }[];
    collateralTokensFiltered: string[];
    apyTimeframe: string;
    collateralTokensOptions: { key: string; value: string }[];
    apyTimeframeOptions: { key: string; value: string }[];
  };
}

export default function RatesChart({
  data,
  callbacks,
  filters
}: RatesChartProps) {
  const theme = useTheme();
  const datasets = useMemo(
    () =>
      Object.keys(
        data.reduce(
          (groupedByPoolId, entry) => {
            const { pool_id } = entry;

            if (pool_id && !groupedByPoolId[pool_id]) {
              groupedByPoolId[pool_id] = true;
            }

            return groupedByPoolId;
          },
          {} as Record<string, boolean>
        )
      ).map((pool_id) => {
        const poolData = data.find((entry) => entry.pool_id === pool_id);
        const color = generateColorFromPool(poolData?.pool_id ?? 'y');

        const formattedLabel = protocolToTitleCase(pool_id);
        return {
          label: formattedLabel,
          borderColor: color,
          xField: 'date',
          yField: 'rate',
          data: data.filter((entry) => entry.pool_id === pool_id),
          borderWidth: 1,
          backgroundColor: color,
          fill: false
        };
      }),
    [data]
  );

  const yScaleValueFormatter = useCallback((value: number | string) => {
    return formatPercentage(Number(value), { maximumFractionDigits: 5 });
  }, []);

  const [, updateChartConfigWithPeriod] = useChartPeriod(filters.timeFilter);

  const chartConfig = useMemo(
    () =>
      updateChartConfigWithPeriod({
        options: {
          // @ts-ignore
          fill: false,
          aspectRatio: 3,
          plugins: {
            legend: {
              display: true,
              position: 'bottom' as const,
              labels: {
                color: theme.colors.muted,
                boxWidth: 20
              }
            },
            tooltip: {
              itemSort: (a: TooltipItem<'line'>, b: TooltipItem<'line'>) => {
                return b.parsed.y - a.parsed.y;
              }
            }
          }
        }
      }),
    [theme.colors.muted, updateChartConfigWithPeriod]
  );

  return (
    <>
      <Flex
        flexDirection={['column', 'row']}
        gap="xs"
        justifyContent="flex-end">
        <Flex
          gap="xs"
          flexWrap={['wrap', 'nowrap']}
          justifyContent={['center', 'flex-end']}>
          <DropdownAssetsFilter
            initialValue={filters.protocolsFiltered}
            titleFormat={(filteredCount) =>
              `Protocols filtered (${filteredCount})`
            }
            callback={callbacks.filterByProtocol}
            key="protocolsFilter"
            options={filters.protocolsOptions}
          />
          <DropdownAssetsFilter
            initialValue={filters.tokensFiltered}
            titleFormat={(filteredCount) =>
              `Tokens filtered (${filteredCount})`
            }
            callback={callbacks.filterByToken}
            key="tokensFilter"
            options={filters.tokensOptions}
          />
          <DropdownAssetsFilter
            initialValue={filters.collateralTokensFiltered}
            titleFormat={(filteredCount) =>
              `Collateral filtered (${filteredCount})`
            }
            callback={callbacks.filterByCollateralToken}
            key="collateralFilter"
            options={filters.collateralTokensOptions}
          />
          <Dropdown
            onChange={callbacks.changeApyTimeframe}
            iconSize="-2"
            initialValue={filters.apyTimeframe}
            options={{
              variants: {
                dropdownHeader: 'secondary'
              }
            }}
            key="apyTimeframe">
            {filters.apyTimeframeOptions.map(({ key, value }) => (
              <DropdownOption key={key} value={key} variant="secondary">
                <Flex gap="3xs" alignItems="center">
                  <Box>{value}</Box>
                </Flex>
              </DropdownOption>
            ))}
          </Dropdown>
        </Flex>
        <Flex>
          <ChartTimeFilter
            key="timeFilter"
            activeOption={filters.timeFilter}
            onChange={callbacks.filterByTime}
            justifyContent={['center', 'flex-end']}
          />
        </Flex>
      </Flex>

      <LineChart
        datasets={datasets}
        config={chartConfig}
        valueFormatter={() => null}
        subValueFormatter={() => null}
        yScaleValueFormatter={yScaleValueFormatter}
        tooltip={{
          labelFormatter: (value) => `${value}`,
          valueFormatter: (value) => `${formatPercentage(Number(value))}`,
          titleFormatter: (_value, tootlipItems) =>
            // @ts-ignore
            `${formatDatetime(tootlipItems[0].raw.x, { format: 'MMM dd, yyyy' })}`
        }}
        showKpisAsTooltip
      />
    </>
  );
}
