import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
        ${(props) =>
          props.theme.breakpoints
            .map((bp: number, index: number) => {
              if (index === 0) return `font-size: ${props.theme.rootFontSize};`;

              return `@media (min-width: ${bp}px) {font-size: calc(${props.theme.rootFontSize} + ${index}px);}`;
            })
            .join('\n')}
    }

    body {
        line-height: 1.2;
        font-variant-numeric: tabular-nums;
    }
`;
