import { ErrorBoundary } from '@/components';
import { Heading, Flex, HomeIcon } from '@blockanalitica/ui';
import * as Sentry from '@sentry/react';
import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

type PageProps = {
  title?: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
};

export default function Page({ title, actions, children }: PageProps) {
  const { pathname } = useLocation();

  const crumbs = useMemo(() => {
    const pathArray = pathname.split('/').filter((v) => v.length > 0);
    const crumbsFromPathArray = pathArray.map((subPath, index) => {
      const href = '/' + pathArray.slice(0, index + 1).join('/');
      const text = subPath[0].toUpperCase() + subPath.slice(1);

      return { href, text };
    });

    const crumbsList = [
      { href: '/', text: <HomeIcon size={16} /> },
      ...crumbsFromPathArray
    ];

    return crumbsList;
  }, [pathname]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <Flex flexDirection="column" gap="m">
        {crumbs.length > 1 ? <Breadcrumbs crumbs={crumbs} /> : null}
        {title || actions ? (
          <Flex
            justifyContent="space-between"
            gap="xs-s"
            flexWrap={['wrap', 'nowrap']}
            alignItems="center">
            {title ? (
              <Flex
                justifyContent="flex-start"
                alignItems="center"
                gap="2xs-xs">
                {typeof title === 'string' ? <Heading>{title}</Heading> : title}
              </Flex>
            ) : null}

            {actions ? (
              <Flex
                alignItems="center"
                gap="xs-s"
                flexWrap={['wrap', 'wrap', 'nowrap']}>
                {actions}
              </Flex>
            ) : null}
          </Flex>
        ) : null}

        {children}
      </Flex>
    </Sentry.ErrorBoundary>
  );
}
