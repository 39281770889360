import { useMemo } from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import type {
  SimpleChartData,
  SimpleChartDataset
} from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';

export default function useChartPrefillDatasetsDates(
  data: SimpleChartData[] | undefined,
  datasets: SimpleChartDataset[],
  days = 7
): SimpleChartData[] | undefined {
  return useMemo(() => {
    if (!data) {
      return data;
    }
    const fakedDays = days - data.length;
    if (fakedDays <= 0) {
      return data;
    }
    const startDate =
      data.length > 0
        ? DateTime.fromISO(String(data[0][datasets[0].xField]))
        : DateTime.now();
    const fakes = _.range(fakedDays).map((days) => {
      const obj: SimpleChartData = {
        [datasets[0].xField]: startDate.minus({ days: days + 1 }).toISO()
      };

      datasets.map((ds) => (obj[ds.yField] = 0));
      return obj;
    });
    fakes.reverse();
    // Copy array to new one (arrays are mutable!)
    const newData = [...data];
    newData.unshift(...fakes);
    return newData;
  }, [data, datasets, days]);
}
