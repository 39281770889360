import { useApi } from '@/api';
import { useParams } from 'react-router';
import { KpiSkeleton } from '@blockanalitica/ui';
import { KpiCard } from '@/components';

type PoolRatesKpisData = {
  underlying_address: string;
  borrow_apy: number | string;
  borrow_apy_7d: number | string;
  borrow_apy_30d: number | string;
  utilization: string;
  borrow_apy_change: number | string;
  borrow_apy_7d_change: number | string;
  borrow_apy_30d_change: number | string;
  utilization_change: number | string;
  borrow_apy_change_percentage: number | string;
  borrow_apy_7d_change_percentage: number | string;
  borrow_apy_30d_change_percentage: number | string;
  utilization_change_percentage: number | string;
};

export default function PoolRatesKpis() {
  const { protocol, poolId } = useParams();

  const [data, error] = useApi<PoolRatesKpisData>({
    url: `/pool/${protocol}/${poolId}/borrow-stats/`
  });

  if (error) {
    throw error;
  }

  if (!data) {
    return (
      <>
        <KpiSkeleton />
        <KpiSkeleton />
        <KpiSkeleton />
        <KpiSkeleton />
      </>
    );
  }

  return (
    <>
      <KpiCard
        key="current-borrow"
        title="Current Borrow Apy"
        data={data}
        field="borrow_apy"
        options={{
          percentage: true,
          notation: 'compact',
          variants: {
            valuePercentageValue: 'dynamic'
          }
        }}
      />
      <KpiCard
        key="7d-avg"
        title="Borrow Apy 7d Avg"
        data={data}
        field="borrow_apy_7d"
        options={{
          percentage: true,
          notation: 'compact',
          variants: {
            valuePercentageValue: 'dynamic'
          }
        }}
      />
      <KpiCard
        key="30d-avg"
        title="Borrow Apy 30d Avg"
        data={data}
        field="borrow_apy_30d"
        options={{
          percentage: true,
          notation: 'compact',
          variants: {
            valuePercentageValue: 'dynamic'
          }
        }}
      />

      <KpiCard
        key="utilization"
        title="Utilization"
        data={data}
        field="utilization"
        options={{
          percentage: true,
          notation: 'compact',
          variants: {
            valuePercentageValue: 'dynamic'
          }
        }}
      />
    </>
  );
}
