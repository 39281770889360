import styled, { css } from 'styled-components';
import { ReactNode } from 'react';

const Button = styled.button`
  ${(props) => css`
    border: solid 1px ${props.theme.colors.primary};
    padding: ${props.theme.space['2xs']} ${props.theme.space['s']};
    border-radius: ${props.theme.sizes['-4']};
    background-color: ${props.theme.colors.primary};
    color: ${props.theme.colors.black};
  `}

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.linkHover};
    border-color: ${(props) => props.theme.colors.linkHover};
  }

  &:disabled {
    background-color: transparent;

    ${(props) => css`
      border-color: ${props.theme.colors.border};
      color: ${props.theme.colors.muted};
    `}

    &:hover {
      cursor: not-allowed;
    }
  }
`;

export interface ApplyButtonProps {
  children: ReactNode | ReactNode[];
}

export default function ApplyButton({ children, ...rest }: ApplyButtonProps) {
  return <Button {...rest}>{children}</Button>;
}
