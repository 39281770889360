import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function displaySwitchOption(theme: Theme): ComponentStyle {
  return {
    componentName: 'displaySwitchOption',
    baseStyle: {
      padding: `${theme.space['xs']} ${theme.space['s']}`,
      fontSize: theme.sizes['0'],
      borderRadius: theme.sizes['-3'],

      ['&:hover']: {
        backgroundColor: theme.colors.foreground,
        color: theme.colors.primary
      }
    },
    states: {
      active: {
        backgroundColor: theme.colors.foreground,

        ['&:hover']: {
          backgroundColor: theme.colors.foreground,
          color: theme.colors.text
        }
      }
    },
    variants: {
      bubble: {
        padding: `${theme.space['2xs']} ${theme.space['xs']}`,
        fontSize: theme.sizes['-1'],
        display: 'flex',
        alignItems: 'center'
      }
    }
  };
}
