import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function headerCell(theme: Theme): ComponentStyle {
  return {
    componentName: 'headerCell',
    baseStyle: {
      fontSize: theme.sizes['-1'],
      color: theme.colors.muted,
      borderBottom: `1px solid ${theme.colors.border}`,
      fontWeight: 600,
      borderRadius: 'unset'
    },
    variants: {
      sticky: {
        alignItems: 'flex-end'
      },
      stuck: {
        borderRadius: 'unset',
        alignItems: 'flex-end'
      }
    }
  };
}
