import {
  PercentageValue,
  Flex,
  CryptoIcon,
  CurrencyValue,
  DataTable,
  Box
} from '@blockanalitica/ui';
import { EntityName, TableCellBottom, Link } from '@/components';
import { TableColumn } from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';
import type { EstimatorPool } from './StablecoinRatesEstimator';
import { protocolToTitleCase } from '@/utils/utils';

type StablecoinRatesEstimatorTableProps = {
  results: EstimatorPool[];
  totalRecords: number;
  pagination: {
    currentPage: number;
    pageSize: number;
    totalRecords: number;
  };
  sorting: {
    currentSorting: string;
    onSortingChange: (sorting: string) => void;
  };
};

export default function StablecoinRatesEstimatorTable({
  results,
  pagination,
  sorting,
  totalRecords
}: StablecoinRatesEstimatorTableProps) {
  const columns: TableColumn<EstimatorPool>[] = [
    {
      header: 'Pool',
      getCell: (row) => (
        <EntityName
          symbol1={<CryptoIcon name={row.protocol_symbol} size="3" />}
          symbol2={<CryptoIcon name={row.underlying_symbol} size="3" />}>
          <Flex gap="3xs" flexDirection="column">
            <Box>{row.pool}</Box>
            <Box color="muted" fontSize="-1">
              {protocolToTitleCase(row.protocol)}
            </Box>
          </Flex>
        </EntityName>
      )
    },
    {
      header: 'Current Borrow Rate',
      getCell: (row) => <PercentageValue value={row.borrow_apy} />,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'borrow_apy'
    },
    {
      header: 'Estimated Borrow Rate',
      getCell: (row) => <PercentageValue value={row.new_borrow_apy} />,
      getCellBottom: (row) => (
        <TableCellBottom checkValue={row.borrow_apy_change}>
          <PercentageValue
            value={row.borrow_apy_change}
            options={{
              trend: true
            }}
          />
        </TableCellBottom>
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'new_borrow_apy'
    },
    {
      header: 'Max Borrow',
      getCell: (row) => (
        <CurrencyValue value={row.max_borrow} options={{ currency: 'USD' }} />
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'max_borrow'
    },
    {
      header: 'Borrowed Amount',
      getCell: (row) => (
        <CurrencyValue
          value={row.borrowed_amount}
          options={{ currency: 'USD' }}
        />
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'borrowed_amount'
    },
    {
      header: 'Total Borrow',
      getCell: (row) => (
        <CurrencyValue
          value={row.new_total_debt}
          options={{ currency: 'USD' }}
        />
      ),
      getCellBottom: (row) => (
        <TableCellBottom checkValue={row.total_debt_change}>
          <CurrencyValue
            value={row.total_debt_change}
            options={{
              trend: true,
              currency: 'USD'
            }}
          />
        </TableCellBottom>
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'total_debt'
    },
    {
      header: 'Pool Utilization',
      getCell: (row) => (
        <>
          {row.utilization !== null ? (
            <PercentageValue value={row.new_utilization} />
          ) : (
            '-'
          )}
        </>
      ),
      getCellBottom: (row) => (
        <TableCellBottom checkValue={row.utilization_change}>
          <PercentageValue
            value={row.utilization_change}
            options={{
              trend: true
            }}
          />
        </TableCellBottom>
      ),
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'utilization'
    }
  ];

  return (
    <DataTable
      rows={results}
      columns={columns}
      getKey={(row) => `${row.protocol}${row.pool_id}`}
      sorting={sorting}
      rowLinkProps={{
        to: (row) => `/${row.protocol}/${row.pool_id}`,
        // @ts-ignore
        linkComponent: Link
      }}
      pagination={{
        ...pagination,
        totalRecords: totalRecords
      }}
    />
  );
}
