import {
  Flex,
  LineChart,
  ChartSkeleton,
  formatPercentage,
  Dropdown,
  DropdownOption,
  PercentageValue
} from '@blockanalitica/ui';
import { useCallback, useState, useMemo } from 'react';
import { useApi } from '@/api';
import { useChartPeriod } from '@/hooks';
import type { SimpleChartData } from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';
import { ChartTimeFilter, Tabs } from '@/components';
import { ChartConfig } from '@blockanalitica/ui/package/types/src/components/charts/Chart';

const apyTimeframes = [
  { key: '1d', value: '1d Avg' },
  { key: '7d', value: '7d Avg' },
  { key: '30d', value: '30d Avg' },
  { key: '90d', value: '90d Avg' },
  { key: '365d', value: '365d Avg' }
];

export default function PoolHistoricRatesGraph({
  poolId,
  protocol
}: {
  poolId: string;
  protocol: string;
}) {
  const [activeTab, setActiveTab] = useState('borrow_apy');
  const [chartDaysAgo, setChartDaysAgo] = useState('30');
  const [apyTimeframe, setApyTimeframe] = useState('7d');

  const [data, error] = useApi<SimpleChartData[]>({
    url: `/pool/${protocol}/${poolId}/stats-history/`,
    queryParams: {
      days_ago: chartDaysAgo,
      apy_timeframe: apyTimeframe
    }
  });

  const [, updateChartConfigWithPeriod] = useChartPeriod(chartDaysAgo);
  const defaultConfig: ChartConfig = useMemo(() => {
    return {
      options: {
        aspectRatio: 3
      }
    };
  }, []);
  const config = updateChartConfigWithPeriod(defaultConfig);

  const changeApyTimeframe = useCallback((timeframe: string | string[]) => {
    setApyTimeframe(timeframe as string);
  }, []);

  const changeTimeFilter = useCallback((days: string) => {
    setChartDaysAgo(days);
  }, []);

  const yScaleValueFormatter = useCallback((value: number | string) => {
    return formatPercentage(Number(value));
  }, []);

  const valueFormatter = useCallback((value: number | string) => {
    return <PercentageValue value={value} variant="dynamic" />;
  }, []);

  const timeFilter = useMemo(() => {
    return (
      <Flex alignContent="center" gap="xs">
        {activeTab !== 'utilization' && (
          <Dropdown
            onChange={changeApyTimeframe}
            iconSize="-1"
            initialValue={apyTimeframe}
            options={{
              variants: {
                dropdownHeader: 'ratesSection'
              }
            }}>
            {apyTimeframes.map(({ key, value }) => (
              <DropdownOption key={key} value={key} variant="secondary">
                <Flex gap="3xs" alignItems="center">
                  {value}
                </Flex>
              </DropdownOption>
            ))}
          </Dropdown>
        )}
        <ChartTimeFilter
          activeOption={chartDaysAgo}
          onChange={changeTimeFilter}
        />
      </Flex>
    );
  }, [
    chartDaysAgo,
    changeTimeFilter,
    changeApyTimeframe,
    apyTimeframe,
    activeTab
  ]);

  const chart = useMemo(
    () => (
      <LineChart
        key={`${activeTab}${apyTimeframe}${chartDaysAgo}`}
        data={data}
        config={config}
        datasets={[
          {
            xField: 'date',
            yField: activeTab
          }
        ]}
        yScaleValueFormatter={yScaleValueFormatter}
        valueFormatter={valueFormatter}
        timeFilter={timeFilter}
      />
    ),
    [
      activeTab,
      timeFilter,
      data,
      config,
      valueFormatter,
      yScaleValueFormatter,
      apyTimeframe,
      chartDaysAgo
    ]
  );

  const tabs = useMemo(
    () => [
      {
        key: 'borrow_apy',
        title: 'Borrow APY',
        content: chart
      },
      {
        key: 'utilization',
        title: 'Utilization',
        content: chart
      }
    ],
    [chart]
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return <ChartSkeleton />;
  }

  return <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />;
}
