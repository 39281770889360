import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function input(theme: Theme): ComponentStyle {
  return {
    componentName: 'input',
    baseStyle: {
      borderColor: theme.colors.border,

      ['&:focus']: {
        borderColor: theme.colors.primary
      }
    },
    variants: {
      massive: {
        backgroundColor: '#16162b',
        fontSize: theme.sizes['3'],
        lineHeight: theme.sizes['4'],
        height: 'auto',
        maxWidth: '200px'
      }
    }
  };
}
