import {
  PercentageValue,
  Flex,
  CryptoIcon,
  CurrencyValue,
  DataTable,
  TableSkeleton,
  Card,
  Dropdown,
  DropdownOption,
  Box
} from '@blockanalitica/ui';
import { useMemo, useCallback, useState } from 'react';
import { EntityName, Link } from '@/components';
import { TableColumn } from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';
import { PaginatedResponse, useApi } from '@/api';
import DropdownAssetsFilter from '@/components/DropdownAssetsFilter';
import { TableCellBottom } from '@/components';
import { protocolToTitleCase } from '@/utils/utils';
import RateTableFilters from './RateTableFilters';

interface ExtendedPaginatedResponse extends PaginatedResponse<Pool[]> {
  stablecoins: { key: string; value: string }[];
  protocols: { key: string; value: string }[];
  collateral_tokens: { key: string; value: string }[];
}

type Pool = {
  pool: string;
  protocol: string;
  rate: string;
  protocol_symbol: string;
  utilization: string;
  total_debt: string;
  underlying_symbol: string;
  total_supply: string;
  borrow_apy: string;
  rate_change: string;
  borrow_apy_change: string;
  total_debt_change: string;
  utilization_change: string;
  pool_id: string;
};

export default function StablecoinRatesTable() {
  const {
    pagination,
    sorting,
    tokensFiltered,
    protocolsFiltered,
    collateralTokensFiltered,
    filterByToken,
    filterByProtocol,
    filterByCollateralToken
  } = RateTableFilters('borrow_apy');

  const apyTimeframes = [
    { key: '1d', value: '1d Avg' },
    { key: '7d', value: '7d Avg' },
    { key: '30d', value: '30d Avg' },
    { key: '90d', value: '90d Avg' },
    { key: '365d', value: '365d Avg' }
  ];
  const [apyTimeFrame, setApyTimeFrame] = useState('7d');

  const [data, error] = useApi<ExtendedPaginatedResponse>(
    {
      url: '/stablecoins/rates/',
      queryParams: {
        p: String(pagination.currentPage),
        p_size: String(pagination.pageSize),
        order: sorting.currentSorting,
        tokens: tokensFiltered.join(','),
        protocols: protocolsFiltered.join(','),
        collateral_tokens: collateralTokensFiltered.join(','),
        apy_timeframe: apyTimeFrame
      }
    },
    {
      keepPreviousData: true
    }
  );

  const changeApyTimeframe = useCallback((timeframe: string | string[]) => {
    setApyTimeFrame(timeframe as string);
  }, []);

  const columns: TableColumn<Pool>[] = useMemo(
    () => [
      {
        header: 'Pool',
        getCell: (row) => (
          <EntityName
            symbol1={<CryptoIcon name={row.protocol_symbol} size="3" />}
            symbol2={<CryptoIcon name={row.underlying_symbol} size="3" />}>
            <Flex gap="3xs" alignItems="flex-start" flexDirection="column">
              <Flex gap="3xs" alignItems="center">
                {row.pool}
              </Flex>
              <Flex gap="3xs" alignItems="center" color="muted" fontSize="-1">
                {protocolToTitleCase(row.protocol)}
              </Flex>
            </Flex>
          </EntityName>
        )
      },
      {
        header: 'Current Borrow Rate',
        getCell: (row) => <PercentageValue value={row.rate} />,
        getCellBottom: (row) => (
          <TableCellBottom checkValue={row.rate_change}>
            <PercentageValue
              value={row.rate_change}
              fontSize={'-1'}
              options={{
                trend: true
              }}
            />
          </TableCellBottom>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'rate'
      },
      {
        header: `${apyTimeFrame} Avg Borrow Rate`,
        getCell: (row) => <PercentageValue value={row.borrow_apy} />,
        getCellBottom: (row) => (
          <TableCellBottom checkValue={row.borrow_apy_change}>
            <PercentageValue
              value={row.borrow_apy_change}
              fontSize={'-1'}
              options={{
                trend: true
              }}
            />
          </TableCellBottom>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'borrow_apy'
      },
      {
        header: 'Total Borrow',
        getCell: (row) => (
          <CurrencyValue value={row.total_debt} options={{ currency: 'USD' }} />
        ),
        getCellBottom: (row) => (
          <TableCellBottom checkValue={row.total_debt_change}>
            <CurrencyValue
              value={row.total_debt_change}
              fontSize={'-1'}
              options={{
                trend: true
              }}
            />
          </TableCellBottom>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'total_debt'
      },
      {
        header: 'Pool Utilization',
        getCell: (row) => (
          <>
            {row.utilization !== null ? (
              <PercentageValue value={row.utilization} />
            ) : (
              '-'
            )}
          </>
        ),
        getCellBottom: (row) => (
          <TableCellBottom checkValue={row.utilization_change}>
            <PercentageValue
              value={row.utilization_change}
              fontSize={'-1'}
              options={{
                trend: true
              }}
            />
          </TableCellBottom>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'utilization'
      }
    ],
    [apyTimeFrame]
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return <TableSkeleton rows={10} columns={columns.length} />;
  }

  return (
    <Card flexDirection="column" gap="s">
      <Flex
        gap="xs"
        flexWrap={['wrap', 'nowrap']}
        justifyContent={['center', 'flex-end']}
        borderBottom="solid 1px #35384b"
        paddingBottom="m">
        <DropdownAssetsFilter
          initialValue={protocolsFiltered}
          titleFormat={(filteredCount) =>
            `Protocols filtered (${filteredCount})`
          }
          callback={filterByProtocol}
          key="protocolsFilter"
          options={data['protocols']}
        />
        <DropdownAssetsFilter
          initialValue={tokensFiltered}
          titleFormat={(filteredCount) => `Tokens filtered (${filteredCount})`}
          callback={filterByToken}
          key="tokensFilter"
          options={data['stablecoins']}
        />
        <DropdownAssetsFilter
          initialValue={collateralTokensFiltered}
          titleFormat={(filteredCount) =>
            `Collateral filtered (${filteredCount})`
          }
          callback={filterByCollateralToken}
          key="collateralFilter"
          options={data['collateral_tokens']}
        />
        <Dropdown
          onChange={changeApyTimeframe}
          iconSize="-2"
          initialValue={apyTimeFrame}
          options={{
            variants: {
              dropdownHeader: 'secondary'
            }
          }}
          key="apyTimeframe">
          {apyTimeframes.map(({ key, value }) => (
            <DropdownOption key={key} value={key} variant="secondary">
              <Flex gap="3xs" alignItems="center">
                <Box>{value}</Box>
              </Flex>
            </DropdownOption>
          ))}
        </Dropdown>
      </Flex>
      <DataTable
        rows={data['results']}
        columns={columns}
        rowLinkProps={{
          to: (row) => `/${row.protocol}/${row.pool_id}`,
          // @ts-ignore
          linkComponent: Link
        }}
        getKey={(row) => `${row.protocol}${row.pool_id}`}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    </Card>
  );
}
