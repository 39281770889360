const PROTOCOL_COLORS: Record<string, string> = {
  aave_v3: '#9896ff',
  aave_v3_lido: '#34b5c4',
  compound_v3: '#00D395',
  sky: '#0E76FD',
  morpho: '#5792ff',
  spark: '#f76d36'
};

function extractProtocolFromPoolId(poolId: string): {
  protocol: string;
  subset: string;
} {
  const parts = poolId.split(' ');
  const protocol = parts[0];
  const subset = parts.slice(1).join('_') || '';
  return { protocol, subset };
}

function hashString(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function LightenDarkenColor(col: string, amt: number) {
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  let b = ((num >> 8) & 0x00ff) + amt;
  let g = (num & 0x0000ff) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  const newColor = (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
  return (usePound ? '#' : '') + newColor;
}

function generateDistinctColor(baseColor: string, subset: string): string {
  const hashVal = hashString(subset);
  const amt = (Math.abs(hashVal) % 101) - 50;

  return LightenDarkenColor(baseColor, amt);
}

export function generateColorFromPool(poolId: string) {
  const { protocol, subset } = extractProtocolFromPoolId(poolId);
  const baseColor = PROTOCOL_COLORS[protocol];
  const newColor = generateDistinctColor(baseColor, subset);
  return newColor;
}

export function protocolToTitleCase(str: string): string {
  return str
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
