import { ReactNode } from 'react';
import { Flex, FlexProps } from '@blockanalitica/ui';

export interface ExternalLinkProps extends FlexProps {
  href: string;
  children: ReactNode | ReactNode[];
}

export default function ExternalLink({
  href,
  children,
  ...rest
}: ExternalLinkProps) {
  return (
    <Flex
      as="a"
      display="flex"
      gap="3xs"
      alignItems="center"
      rel="noreferrer"
      // @ts-ignore
      href={href}
      target="_blank"
      {...rest}>
      {children}
    </Flex>
  );
}
