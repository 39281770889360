import apiFetcher, { apiFetcherArgs } from '@/api/apiFetcher';
import useSWR from 'swr';

export default function useApi<T>(
  args: apiFetcherArgs,
  swrConfig?: {
    keepPreviousData?: boolean;
  }
): [T | undefined, Error | undefined] {
  const { data, error } = useSWR<T, Error, apiFetcherArgs>(args, apiFetcher, {
    keepPreviousData: swrConfig?.keepPreviousData
  });

  return [data, error];
}
``;
