import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function kpiCardFooter(theme: Theme): ComponentStyle {
  return {
    componentName: 'kpiCardFooter',
    baseStyle: {
      fontSize: theme.sizes[0],
      color: theme.colors.muted
    }
  };
}
