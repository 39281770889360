import { extendTheme } from '@blockanalitica/ui';
import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { colors } from './colors';
import { card } from './components/card';
import { displaySwitch } from './components/displaySwitch';
import { displaySwitchOption } from './components/displaySwitchOption';
import { headerCell } from './components/headerCell';
import { table } from './components/table';
import { input } from './components/input';
import { tableCell } from './components/tableCell';
import sizes from './sizes';
import space from './space';
import { dropdown } from './components/dropdown';
import { dropdownHeader } from './components/dropdownHeader';
import { dropdownList } from './components/dropdownList';
import { dropdownOption } from './components/dropdownOption';
import { kpiHeader } from './components/kpiHeader';
import { kpiCardFooter } from './components/kpiCardFooter';
import { chartKpiSubValue } from './components/chartKpiSubValue';
import { chartKpiValue } from './components/chartKpiValue';

export const theme: Theme = extendTheme({
  rootFontSize: '13px',
  charts: {
    minFontSize: 9,
    maxFontSize: 12
  },
  colors,
  sizes,
  space,
  components: [
    card,
    table,
    tableCell,
    headerCell,
    input,
    dropdown,
    dropdownHeader,
    dropdownList,
    dropdownOption,
    displaySwitch,
    displaySwitchOption,
    kpiHeader,
    kpiCardFooter,
    chartKpiSubValue,
    chartKpiValue
  ]
});
