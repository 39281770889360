export const colors = {
  background: '#060507',
  foreground: '#20203E',
  onSurface: '#13131E',
  muted: '#7a7ea0',
  text: '#f7f9fc',
  black: '#060507',
  primary: '#8585FF',
  success: '#1dd9ba',
  error: '#ff6d6d',
  warning: '#ff832b',
  link: '#8585FF',
  linkHover: '#5e5eff',
  transparent: 'transparent',
  border: '#35384b',
  tooltip: '#1c1828',
  white85: 'rgba(255, 255, 255, 0.85)',
  chartLine: '#8585FF',
  chartIndicatorLine: '#909090',
  chartUp: '#45C8E5',
  chartDown: '#A979D8',
  tableRowHover: '#222230',
  tableStickyBg: '#13131E',
  tabInactive: '#222230',
  skeleton: {
    baseColor: '#13131E',
    highlightColor: '#1c1c2c'
  }
};
