import { DisplaySwitch } from '@blockanalitica/ui';
import type { DisplaySwitchProps } from '@blockanalitica/ui/package/types/src/components/molecules/DisplaySwitch/DisplaySwitch';

const defaultOptions = [
  { key: '30', value: '1M' },
  { key: '90', value: '3M' },
  { key: '365', value: '1Y' },
  { key: '9999', value: 'MAX' }
];

interface ChartTimeFilterProps
  extends Omit<Partial<DisplaySwitchProps>, 'onChange'> {
  onChange: (optionKey: string) => void;
}

export default function ChartTimeFilter({
  activeOption = '30',
  data = defaultOptions,
  onChange,
  ...rest
}: ChartTimeFilterProps) {
  return (
    <DisplaySwitch
      variant="bubble"
      data={data}
      activeOption={activeOption}
      onChange={onChange}
      options={{
        variants: {
          displaySwitchOption: 'bubble'
        }
      }}
      {...rest}
    />
  );
}
