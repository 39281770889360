import { ReactNode } from 'react';
import { Flex, FlexProps, Box } from '@blockanalitica/ui';
import styled from 'styled-components';

export interface EntityProps {
  $entitySize: string;
}

const Entity = styled.div<EntityProps>`
  margin-left: calc(${(props) => props.theme.sizes[props.$entitySize]} / -3);

  &:first-child {
    margin: 0;
  }
`;

export interface EntityListProps extends FlexProps {
  showTotalNumber?: boolean;
  entitySize?: string;
  children: ReactNode[];
}

export default function EntityList({
  showTotalNumber = true,
  entitySize = '0',
  children,
  ...rest
}: EntityListProps) {
  return (
    <Flex alignItems="center" gap="2xs" {...rest}>
      {showTotalNumber ? <Box>{children.length}</Box> : null}
      <Flex>
        {children.map((child, index) => (
          <Entity $entitySize={entitySize} key={index}>
            {child}
          </Entity>
        ))}
      </Flex>
    </Flex>
  );
}
